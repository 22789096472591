import React, {Component} from 'react';
import ReactCSSTransitionGroup from 'react-addons-css-transition-group';
import {Prompt, RouteComponentProps} from 'react-router';
import {Link} from 'react-router-dom';
import {toast} from 'react-toastify';
import {Button, Card, CardBody, CardTitle, Col, Form, Row} from 'reactstrap';
import {PageTitle} from '../../../components/layout/pageTitle';
import {LInput, LText} from '../../../components/lInput';
import Loading from '../../../components/loading';
import {PromiseButton} from '../../../components/promiseButton';
import {HttpCardExpanded} from '../../../dataServices/app.generated';
import {AppService} from '../../../dataServices/appService';
import {DeleteModal} from '../../../modals/deleteModal';
import {NewCardParentModal} from '../../../modals/newCardParentModal';
import {MainStore} from '../../../store/main/store';
import {FormatUtils} from '../../../utils/formatUtils';

type Props = RouteComponentProps<{cardId: string}> & {};
type State = {
  card?: HttpCardExpanded;
  isModified: boolean;
  showNewPartnerModal: boolean;
  showDeleteCard: boolean;
};

export class CardDetails extends Component<Props, State> {
  constructor(props: Props, context: any) {
    super(props, context);

    this.state = {
      isModified: false,
      showNewPartnerModal: false,
      showDeleteCard: false,
      card: null,
    };
  }

  async componentDidMount() {
    await this.getCard();
  }

  async componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<State>, snapshot?: any) {
    if (prevProps.match.params.cardId !== this.props.match.params.cardId) {
      await this.getCard();
    }
  }

  render() {
    return (
      <ReactCSSTransitionGroup
        component="div"
        transitionName="TabsAnimation"
        transitionAppear={true}
        transitionAppearTimeout={0}
        transitionEnter={false}
        transitionLeave={false}
      >
        {this.state.card ? this.renderCard() : <Loading />}
        <Prompt
          when={this.state.isModified}
          message={(location) => `You have unsaved changes, are you sure you want leave?`}
        />
      </ReactCSSTransitionGroup>
    );
  }

  private renderCard() {
    const card = this.state.card;
    return (
      <>
        <PageTitle
          heading={`Card ${card.groupCode}-${card.memberId}`}
          subheading=""
          icon="pe-7s-medal icon-gradient bg-tempting-azure"
          actions={
            <>
              <Button
                className="btn-pill btn-shadow mr-3"
                onClick={() => this.setState({showNewPartnerModal: true})}
                size={'lg'}
                color="primary"
              >
                Change Parent Partner
              </Button>
              <Button
                className="btn-pill btn-shadow mr-3"
                onClick={() => this.setState({showDeleteCard: true})}
                size={'lg'}
                color="warning"
              >
                Delete Card {card.groupCode}-{card.memberId}
              </Button>
            </>
          }
        />
        <Row>
          <Col md="12">
            <Card className="main-card mb-3">
              <CardBody>
                <CardTitle>Details</CardTitle>
                <Form>
                  <Row>
                    <Col md={6}>
                      <LInput
                        value={card.groupCode}
                        onChange={(e) => {
                          this.setState({
                            isModified: true,
                            card: {...this.state.card, groupCode: e.target.value},
                          });
                        }}
                        label={'Group Code'}
                        type={'text'}
                      />
                    </Col>
                    <Col md={6}>
                      <LInput
                        value={card.memberId}
                        onChange={(e) => {
                          this.setState({
                            isModified: true,
                            card: {...this.state.card, memberId: e.target.value},
                          });
                        }}
                        label={'Member Id'}
                        type={'text'}
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col md={6}>
                      <LInput
                        label={'Company Name'}
                        value={card.companyName}
                        onChange={(e) =>
                          this.setState({
                            isModified: true,
                            card: {...this.state.card, companyName: e.target.value},
                          })
                        }
                        placeholder={'optional'}
                      />
                    </Col>
                    <Col md={6}>
                      <LInput
                        label={'Url Slug'}
                        value={card.urlSlug}
                        onChange={(e) =>
                          this.setState({
                            isModified: true,
                            card: {...this.state.card, urlSlug: e.target.value},
                          })
                        }
                      />
                    </Col>
                  </Row>
                  <Row style={{display: 'flex', justifyContent: 'flex-end'}}>
                    <PromiseButton
                      disabled={!this.state.isModified}
                      onClick={this.updateCard}
                      className="btn-pill btn-shadow mr-3"
                      color="primary"
                    >
                      Save Changes
                    </PromiseButton>
                  </Row>
                  <Row>
                    <Col md={12}>
                      <CardTitle>Notes</CardTitle>
                      {card.notes.map((n) => n.note)}
                    </Col>
                  </Row>
                </Form>
              </CardBody>
            </Card>
            <Card className="main-card mb-3">
              <CardBody>
                <CardTitle>Partner</CardTitle>
                <Row>
                  <Col md={6}>
                    <LText label={''} style={{fontWeight: 'bold', fontSize: '1.4em'}}>
                      <Link to={`/partner/details/${card.partner.id}`}>{card.partner.partnerName}</Link>
                      <span style={{paddingLeft: 5}} className={'money'}>
                        ({FormatUtils.formatMoney(card.partner.commission.commissionAmount)})
                      </span>
                    </LText>
                  </Col>
                </Row>
              </CardBody>
            </Card>
            {card.account && (
              <Card className="main-card mb-3">
                <CardBody>
                  <CardTitle>Account</CardTitle>
                  <Row>
                    <Col md={6}>
                      <LText label={'Account Owner'} style={{fontWeight: 'bold', fontSize: '1.4em'}}>
                        <Link to={`/account/details/${card.account.id}`}>
                          {card.account.accountOwner
                            ? `${card.account.accountOwner} (${card.account.email})`
                            : card.account.email}
                        </Link>
                      </LText>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            )}
          </Col>
        </Row>
        {this.state.showNewPartnerModal && (
          <NewCardParentModal
            card={this.state.card}
            {...this.props}
            close={() => this.setState({showNewPartnerModal: false})}
          />
        )}
        {this.state.showDeleteCard && (
          <DeleteModal
            text={'Are you sure you want to delete this Card?'}
            {...this.props}
            onSuccess={async () => {
              if (
                await AppService.adminPartnerClient.deleteCard(
                  {
                    cardId: this.state.card.id,
                  },
                  {
                    ...MainStore.handleError(400),
                  }
                )
              ) {
                this.props.history.push(`/partner/details/${this.state.card.partnerId}`);
              }
            }}
            close={() => this.setState({showDeleteCard: false})}
          />
        )}
      </>
    );
  }

  private async getCard() {
    this.setState({card: null, showNewPartnerModal: false, showDeleteCard: false, isModified: false});
    const result = await AppService.adminPartnerClient.getCard(
      {
        cardId: this.props.match.params.cardId,
      },
      {
        ...MainStore.handleError(404, () => {
          this.props.history.push('/');
        }),
        ...MainStore.handleError(400),
      }
    );
    if (result) {
      this.setState({card: result.card});
    }
  }

  private updateCard = async () => {
    const result = await AppService.adminPartnerClient.updateCard(
      {
        cardId: this.state.card.id,
        companyName: this.state.card.companyName,
        groupCode: this.state.card.groupCode,
        memberId: this.state.card.memberId,
        urlSlug: this.state.card.urlSlug,
        partnerId: this.state.card.partnerId,
      },
      {
        ...MainStore.handleError(400),
      }
    );
    if (result) {
      this.setState({isModified: false, card: result.card});
      toast('Card has been updated!', {type: 'success'});
    }
  };
}
