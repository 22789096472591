export const Navigation = [
  {
    icon: 'pe-7s-piggy',
    label: 'Reports',
    content: [
      {
        label: 'Executive Report',
        to: '/reports/executive',
      },
      {
        label: 'Masters Report',
        to: '/reports/masters',
      },

      {
        label: 'Weekly Report',
        to: '/reports/main',
      },
      {
        label: 'Slug Report',
        to: '/reports/slugs',
      },
    ],
  },
  {
    icon: 'pe-7s-network',
    label: 'Partners',
    content: [
      {
        label: 'Masters',
        to: '/partner/masters',
      },
      {
        label: 'Partner Search',
        to: '/partner/search',
      },
      {
        label: 'Payouts Due',
        to: '/partner/amounts-due',
      },
    ],
  },
  {
    icon: 'pe-7s-browser',
    label: 'Accounts',
    content: [
      {
        label: 'Account Search',
        to: '/account/search',
      },
    ],
  },
  {
    icon: 'pe-7s-medal',
    label: 'Claims',
    content: [
      {
        label: 'Monthly Report',
        to: '/claims/reports',
      },
      {
        label: 'Payout Requests',
        to: '/claims/payout-requests',
      },
      {
        label: 'Claim Reconciliation',
        to: '/claims/reconcile',
      },
      {
        label: 'Claims Report',
        to: '/reports/claims',
      },
      {
        label: 'Claims Import Report',
        to: '/reports/claims-import',
      },
    ],
  },
  {
    icon: 'pe-7s-medal',
    label: 'Management',
    content: [
      {
        label: 'Partner Requests',
        to: '/management/requests',
      },
      {
        label: 'Actions',
        to: '/management/actions',
      },
      {
        label: 'Marketing Materials',
        to: '/management/marketing-materials',
      },
    ],
  },
];
