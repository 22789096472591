import React, {Fragment} from 'react';
import {Redirect, Route} from 'react-router-dom';
import {ToastContainer} from 'react-toastify';
import {AdminRoute} from '../components/adminRoute';
import {AccountPages} from './account';
import {CardPages} from './card';
import {ClaimPages} from './claims';
import {ManagementPages} from './management';
import {PartnerPages} from './partner';
import {ReportPages} from './reports';
import {UserPages} from './user';

const AppMain = () => {
  return (
    <Fragment>
      <AdminRoute path="/partner" component={PartnerPages} />
      <AdminRoute path="/account" component={AccountPages} />
      <AdminRoute path="/card" component={CardPages} />
      <AdminRoute path="/claims" component={ClaimPages} />
      <AdminRoute path="/reports" component={ReportPages} />
      <AdminRoute path="/management" component={ManagementPages} />
      <Route path="/user" component={UserPages} />
      <Route exact path="/" render={() => <Redirect to="/partner/search" />} />
      <ToastContainer position={'bottom-right'} />
    </Fragment>
  );
};

export default AppMain;
