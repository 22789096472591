import React, {Component} from 'react';
import {RouteComponentProps} from 'react-router';
import {Modal, ModalBody, ModalFooter, ModalHeader} from 'reactstrap';
import {PartnerSearchComponent} from '../components/partnerSearchComponent';
import {PromiseButton} from '../components/promiseButton';
import {HttpCardExpanded, HttpPartnerSearch} from '../dataServices/app.generated';
import {AppService} from '../dataServices/appService';
import {MainStore} from '../store/main/store';

type Props = RouteComponentProps<{}> & {
  card: HttpCardExpanded;
  close: () => void;
};
type State = {
  newParentPartner?: HttpPartnerSearch;
};

export class NewCardParentModal extends Component<Props, State> {
  constructor(props: Props, context: any) {
    super(props, context);

    this.state = {};
  }

  private setNewParent = async () => {
    const result = await AppService.adminPartnerClient.updateCard(
      {
        partnerId: this.state.newParentPartner._id,
        cardId: this.props.card.id,
        companyName: this.props.card.companyName,
        groupCode: this.props.card.groupCode,
        memberId: this.props.card.memberId,
        urlSlug: this.props.card.urlSlug,
      },
      {
        ...MainStore.handleError(400),
      }
    );
    if (result) {
      window.location.reload();
    }
  };

  render() {
    return (
      <Modal isOpen={true}>
        <ModalHeader toggle={this.props.close}>New Parent</ModalHeader>
        <ModalBody>
          <PartnerSearchComponent onPartnerSelect={(p) => this.setState({newParentPartner: p})} />
          {this.state.newParentPartner && (
            <div style={{marginTop: 10}}>
              Selected Parent Partner: <b>{this.state.newParentPartner.partnerName}</b>
            </div>
          )}
        </ModalBody>
        <ModalFooter>
          <PromiseButton onClick={this.setNewParent} color={'primary'}>
            Update Parent
          </PromiseButton>
        </ModalFooter>
      </Modal>
    );
  }
}
